import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ThemeContext } from 'styled-components';
import { DefaultFooter } from '@luigiclaudio/ga-baseline-ui/footer';
import { A } from '@luigiclaudio/ga-baseline-ui/typography';
import { GaIcon } from '@luigiclaudio/ga-baseline-ui/icon';
import { useHasMounted, TransitionItem, helpersUtils } from '@luigiclaudio/ga-baseline-ui/helpers';
import SiteFooterContainer from './SiteFooterContainer';
import MissionContentContainer from './MissionContentContainer';

const SiteFooter = ({ inProp }) => {
    const intl = useIntl();
    const hasMounted = useHasMounted();
    const theme = useContext(ThemeContext);

    if (!hasMounted) {
        return null;
    }

    return (
        <SiteFooterContainer inProp={inProp}>
            <TransitionItem appear inProp={inProp}>
                <DefaultFooter
                    copyrightOwner={intl.formatMessage({ id: 'footer.copyright.owner' })}
                    logoPath="/assets/common/logo-dark.svg"
                    logoPathAlt="/assets/common/logo-light.svg"
                    logoHeight={28}
                    isFullWidth
                    missionContent={
                        <>
                            <MissionContentContainer>
                                {intl.formatMessage({
                                    id: 'footer.mission.content',
                                })}
                            </MissionContentContainer>
                            <A
                                key={helpersUtils.generateUniqueId()}
                                path="https://twitter.com/hounderly"
                                target="_blank"
                            >
                                {intl.formatMessage(
                                    {
                                        id: 'footer.credits.content',
                                    },
                                    {
                                        twitterIcon: (
                                            <GaIcon
                                                icon="twitter"
                                                size={16}
                                                color={theme.site.footer.twitter.iconColor}
                                            />
                                        ),
                                    },
                                )}
                            </A>
                        </>
                    }
                    showDivider
                />
            </TransitionItem>
        </SiteFooterContainer>
    );
};

SiteFooter.propTypes = {
    inProp: PropTypes.bool,
};

SiteFooter.defaultProps = {
    inProp: true,
};

export default SiteFooter;
