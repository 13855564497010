import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { MailchimpSubscribeFeature } from '@luigiclaudio/ga-mailchimp-signup';
import { useHasMounted } from '@luigiclaudio/ga-baseline-ui/helpers';
import { DefaultTemplate } from '@luigiclaudio/ga-baseline-ui/template';
import { Container, Row, Col, SiteContentWrapper } from '@luigiclaudio/ga-baseline-ui/layout';
import MainContainer from '../components/pageLayout/MainContainer';
import SiteFooter from '../components/pageLayout/SiteFooter';

const MailchimpSubscribeFeaturePage = () => {
    const theme = useContext(ThemeContext);
    const hasMounted = useHasMounted();
    const renderBgColor = hasMounted ? theme.site.stage.backgroundColor : null;

    return (
        <DefaultTemplate
            bgStart={renderBgColor}
            bgEnd={renderBgColor}
            content={
                <SiteContentWrapper>
                    <Container isFullHeight>
                        <Row isFullHeight justifyContent="center">
                            <Col xxs={30}>
                                <MainContainer>
                                    {hasMounted && (
                                        <MailchimpSubscribeFeature
                                            baseUrl={process.env.GATSBY_MAILCHIMP_AUDIENCE_BASE_URL}
                                            u={process.env.GATSBY_MAILCHIMP_AUDIENCE_U}
                                            id={process.env.GATSBY_MAILCHIMP_AUDIENCE_ID}
                                            buttonBtnType="outlined"
                                        />
                                    )}
                                </MainContainer>
                            </Col>
                        </Row>
                    </Container>
                </SiteContentWrapper>
            }
            footer={<SiteFooter />}
        />
    );
};
export default MailchimpSubscribeFeaturePage;
